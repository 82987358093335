/** @jsx jsx */
import { jsx } from "theme-ui"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const Menu = ({ isOpen }) => {
  const { menuLinks } = useSiteMetadata()

  const menuLiItems = menuLinks.map(link => (
    <li key={link.id}>
      <AniLink
        fade
        duration={0.35}
        to={`${link.link}`}
        activeStyle={{
          color: "#00d3dc",
          cursor: "default",
        }}
        sx={{
          variant: "styles.menuItem",
          textDecoration: "none",
          fontSize: "18px",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        {link.shortName ? link.shortName : link.name}
      </AniLink>
    </li>
  ))

  return (
    <div className={isOpen ? "menu open" : "menu"}>
      <ul>{menuLiItems}</ul>
    </div>
  )
}

export default Menu
