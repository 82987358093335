/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Footer = ({ companyName }) => (
  <footer
    sx={{
      verticalAlign: "bottom",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      p: [3, 4, 4],
      variant: "styles.footer",
    }}
  >
    <div style={{ textAlign: "center" }}>
      © {companyName} {new Date().getFullYear()}. Registered in England &amp;
      Wales. Company No. 7142861
    </div>

    <Link
      to="/privacy-policy/"
      sx={{
        variant: "styles.a",
        color: "textWhite",
        ml: ["auto", 2, 2],
        mr: "auto",
      }}
    >
      Privacy policy
    </Link>
  </footer>
)

Footer.propTypes = {
  companyName: PropTypes.string,
}

Footer.defaultProps = {
  companyName: ``,
}

export default Footer
