/** @jsx jsx */
import { jsx, Button } from "theme-ui"
import { useState } from "react"
import Helmet from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import logo from "../images/thinkanew-media-full-logo-white-bg.svg"
import BurgerIcon from "./BurgerIcon"
import Menu from "./Menu"
import "../styles/menu-styles.css"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <header
      sx={{
        marginBottom: "0px",
        pt: "0px",
        pb: "0px",
        pl: ["10px", 5, "80px"],
        pr: [0, 5, "80px"],
      }}
    >
      <div
        sx={{
          display: "flex",
        }}
      >
        <AniLink
          fade
          duration={0.35}
          to="/"
          sx={{
            paddingTop: ["8px", "8px", "6px"],
            paddingBottom: ["2px", "2px", "4px"],
          }}
        >
          <img
            src={logo}
            loading="eager"
            alt="Thinkanew Media Ltd logo"
            sx={{
              height: ["45px", "50px", "55px"],
              verticalAlign: "middle",
            }}
          />
        </AniLink>
        <div sx={{ mx: "auto" }} />
        <Button
          sx={{
            backgroundColor: "#fff",
            border: "none",
            borderRadius: "0px",
            padding: "0px",
            width: "fit-content",
            height: "100%",
            mt: "0px",
          }}
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label="Site menu"
        >
          <BurgerIcon open={menuOpen} />
        </Button>
      </div>
      <Menu isOpen={menuOpen} />
      <Helmet>
        <body className={menuOpen ? "locked" : ""} />
      </Helmet>
    </header>
  )
}

export default Header
