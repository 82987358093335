/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const { siteTitle, companyName } = useSiteMetadata()

  return (
      <div
        sx={{
          margin: "0 auto",
          maxWidth: 1600,
          padding: "0px",
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
        }}
        id="outer-container"
      >
        <Header siteTitle={siteTitle} />
        <main
          style={{
            margin: "0 auto",
            width: "100%",
            padding: "0px 0px 50px 0px",
            paddingTop: 0,
            flex: 1,
            backgroundColor: "#f5f5f5",
          }}
          id="page-wrap"
        >
          {children}
        </main>
        <Footer companyName={companyName} />
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
